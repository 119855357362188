import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import tracking from '~/params/reminder.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ReminderUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { espaceId, reminderId },
  } = props;

  return (
    <TemplateEspace
      collectionName="reminders"
      docId={reminderId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          docId={reminderId}
          itemPathnamePrefix={`/espaces/${espaceId}/tasks/reminders/`}
          model={
            new GenericData({
              collectionName: 'reminders',
              espaceId: espace.id,
              params: tracking,
            })
          }
          name="reminder"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ReminderUpdate);
